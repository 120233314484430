.body {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  min-width: fit-content;
}

.Text1 {
  color: #000;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4em;
  width: 70%;
}

.Text2 {
  color: #616161;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4em;
  width: 70%;
  text-align: center;
}

.Text3 {
  color: #616161;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4em;
  width: 70%;
  text-align: center;
}

.check {
  width: 100%;
}

.pageTitle {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  font-size: 2.2em;
  font-weight: bolder;
  color: black;
  text-align: center;
}

.title2 {
  font-size: 2em;
  font-weight: 800;
  display: flex;
  text-decoration: none !important;
  margin-right: 4%;
  margin-left: 4.5%;
}

.title3 {
  font-size: 2em;
  font-weight: 800;
  display: flex;
  text-decoration: none !important;
  margin-right: 4%;
  margin-left: 4.5%;
}

.intro2 {
  margin-left: 4.5%;
}

.icons {
  color: #2962ff;
  width: 1.5em !important;
  height: 1.5 !important;
}

.icons2 {
  color: bla;
  width: 1.5em !important;
  height: 1.5 !important;
}

.linky {
  color: #2962ff;
  text-decoration: none !important;
}

.linky:visited {
  color: #2962ff;
}

.linky:hover {
  color: #2962ff;
  cursor: pointer;
}

.linky2 {
  color: black;
  text-decoration: none !important;
}
.linky2:visited {
  color: black;
  text-decoration: none !important;
}

.linky2:hover {
  color: black;
  cursor: pointer;
}

.navLinks {
  color: black;
  cursor: pointer;
  justify-content: center;
}

.navLinks:visited {
  color: black;
  cursor: pointer;
}

.navLinks:hover {
  color: #2962ff;
  cursor: pointer;
}

.navLinks:active {
  cursor: pointer;
  background-color: yellow;
}

.blogPost {
  color: black;
  font-size: 1.1em;
  font-weight: 400;

  color: #2962ff;
}

.blogPost:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.1em;
  color: #2962ff;
}

body {
  padding-top: 2%;
  padding-bottom: 2%;
}

@media only screen and (min-width: 700px) {
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-right: 0%;
  }
  .mobileNav {
    display: none;
  }
}

@media only screen and (max-width: 699px) {
  .projectImage {
    width: 90%;
    height: 60%;
    border-radius: 10%;
    border: black;
    border-style: solid;
  }

  .container {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .hello {
    display: none;
  }
  .title3 {
    margin: 0;
  }
  .intro2 {
    margin: 0;
  }
}

.item {
  width: 100%;
}

.projectTitle {
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 800;
  color: black;
  text-align: center;
}

.mobileNav {
  margin-left: 4.5%;
}

s.transform {
  text-transform: uppercase;
  color: #008cba;
}

.hello {
  width: 95%;
  height: 100%;
  margin-right: 3%;
  margin-left: 3%;
  font-size: 1.2em;
  color: #000;
}

.footer {
  padding-top: 2%;
  color: #777;
  border-top: 1px solid #e5e5e5;
  padding-left: 1.8%;
  padding-right: 1.8%;
}

.projectImage {
  width: 90%;
  height: 60%;

  margin-right: 5%;
  border-radius: 10%;
  border: black;
  border-style: solid;
}

.projectImage:hover {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.contactImage {
  margin-right: 2%;
}

.try {
  font-size: 1.4em;
  font-weight: 500;
}

.btn {
  color: #fff;
  background-color: #2962ff;

  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1%;
  padding-right: 1%;
}

.btn:visited {
  color: #fff;
  background-color: #2962ff;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1%;
  padding-right: 1%;
}

.btn:hover {
  color: #fff;
  background-color: #2962ff;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1%;
  padding-right: 1%;
}
.btn:active {
  color: #fff;
  background-color: #2962ff;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1%;
  padding-right: 1%;
}

.blackWriting {
  color: black;
}

.greyImage {
  fill: currentColor;
  filter: grayscale(100%);
}

.profile {
  filter: grayscale(100%);
  border-radius: 50%;
}

/*font-family: 'Ubuntu', sans-serif;*/

.projectDescription {
  width: 100%;
}

.menuIcon {
  margin-top: 25%;
  margin-bottom: 15%;
  width: 1.6em !important;
  height: 60% !important;
}

.MuiButtonBase-root
  MuiListItem-root
  MuiMenuItem-root
  MuiMenuItem-gutters
  MuiListItem-gutters
  MuiListItem-button {
  justify-content: center;
}
